import React from 'react';
import Heading from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Select from '@frontend/ui-kit/Components/Select';
import Separator from '@frontend/ui-kit/Components/Separator';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Input from '@frontend/ui-kit/Components/Input';
import Switcher from '@frontend/ui-kit/Components/Switcher';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import {Field, FieldArray} from '../../shared/FormComponents';
import {ELIGIBILITY_OPTIONS} from '../../../options';
import {TELADOC_ELIGIBILITY_TYPES} from '../../../constants';
import {formatDate, normalizePositiveNumber} from '../../../utils';
import useFormState from '../../../hooks/useFormState';
import './index.scss';

const TeladocPrimary360 = () => {
    const {values} = useFormState();

    return (
        <div className='app-configuration-content'>
            <ContentSection className='app-configuration-content__section'>
                <Heading className='app-configuration-content__title'>General Information</Heading>

                <Row>
                    <Column sm={4}>
                        <Input value={formatDate(values.start_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Effective Date'/>
                    </Column>
                    <Column sm={4}>
                        <Input value={formatDate(values.end_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Expiration Date'/>
                    </Column>
                    <Column sm={4}>
                        <Field name='is_consult_fee_applied_to_deductible' className='checkbox-middle'>
                            {props => <Checkbox {...props} caption='Consult Fee Applied to Deductible' disabled/>}
                        </Field>
                    </Column>
                </Row>

                <Separator/>

                <FieldArray name='consult_fees'>{({fields = []}) => (
                    <React.Fragment>
                        {fields.map((field, index) => {
                            const consultFeePostfix = fields.value[index].segment === TELADOC_ELIGIBILITY_TYPES.HDHPOnly ? '- HSA Only' : '';

                            return (
                                <React.Fragment>
                                    <Row middle='xs' className='mb-13'>
                                        <Column sm={4}>
                                            <Field name={`${field}.segment`}>
                                                {props => <Select {...props} options={ELIGIBILITY_OPTIONS} disabled label='Eligibility' description='This field is populated from Salesforce' isRequired/>}
                                            </Field>
                                        </Column>
                                    </Row>

                                    <Text type={TEXT_TYPES.bodyBold} className='mb-6'>Member Consult Fees</Text>
                                    <Text type={TEXT_TYPES.helper} className='mb-13'>Please work with Tech Ops to ensure fees are updated appropriately with Teladoc.</Text>
                                    <Row>
                                        <Column sm={4}>
                                            <Field name={`${field}.initial_visit_registrant_fee`} parse={normalizePositiveNumber}>
                                                {props => <Input {...props} icon={ICON_TYPES.money} placeholder='' disabled label={`Initial Consult ${consultFeePostfix}`} description='This field is populated from Salesforce'/>}
                                            </Field>
                                        </Column>
                                        <Column sm={4}>
                                            <Field name={`${field}.additional_visits_registrant_fee`} parse={normalizePositiveNumber}>
                                                {props => <Input {...props} icon={ICON_TYPES.money} placeholder='' disabled label={`Subsequent Visits ${consultFeePostfix}`} description='This field is populated from Salesforce'/>}
                                            </Field>
                                        </Column>
                                    </Row>

                                    {index + 1 < ELIGIBILITY_OPTIONS.length && <Separator/>}
                                </React.Fragment>
                            );
                        })}
                    </React.Fragment>
                )}
                </FieldArray>
            </ContentSection>
        </div>
    );
};

export {TeladocPrimary360 as TestableTeladocPrimary360};
export default React.memo(TeladocPrimary360);

import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import WelcomeLayout from '../../../Components/Broadcaster/WelcomeLayout';

const PAGE_HEADER_CAPTION = 'Home for your benefits communication. Start with a template or create a custom broadcast.';

const Welcome = () => {
    return (
        <React.Fragment>
            <Row>
                <Column sm={9}>
                    <PageHeader title='Welcome to Broadcaster' caption={PAGE_HEADER_CAPTION}/>
                </Column>
            </Row>

            <WelcomeLayout/>
        </React.Fragment>
    );
};

export {Welcome as TestableWelcome};
export default React.memo(Welcome);

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Heading from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Separator from '@frontend/ui-kit/Components/Separator';
import Input from '@frontend/ui-kit/Components/Input';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {Field, FieldArray} from '../../../shared/FormComponents';
import {requestGroupIdGenerate} from '../../../../actions/adminPortal';
import useFormState from '../../../../hooks/useFormState';
import {getEqual, validateSpaceAbsence} from '../../../../utils';
import {ELIGIBILITY_OPTIONS} from '../../../../options';
import './index.scss';

/* istanbul ignore next */
const validate = values => {
    return {
        eligibility_groups: values.eligibility_groups?.map(group => ({
            group_id: validateSpaceAbsence(group.group_id)
        }))
    };
};

const EligibilityInformation = ({isEditDisabled}) => {
    const dispatch = useDispatch();
    const {companyAlias} = useParams();
    const {values} = useFormState();
    const [tooltip, setTooltip] = useState('Copy');

    const copyToClipboard = value => {
        navigator.clipboard.writeText(value);
        setTooltip('Copied to Clipboard');
    };

    const getGeneratedGroupId = async () => {
        const {data, isSuccess} = await dispatch(requestGroupIdGenerate());

        return isSuccess ? data.group_id : null;
    };

    return (
        <ContentSection className='app-configuration-content__section'>
            <Heading className='app-configuration-content__title'>Eligibility Information</Heading>

            <Row>
                <Column sm={8}>
                    <Column sm={8}>
                        <Input value={values.eligibility_definition} placeholder='All Employees in file' description='This field is populated from step 4 of the ‘Eligibility Overview’ section in Dashboard' disabled label='Eligibility Definition'/>
                    </Column>
                </Column>
            </Row>

            <Separator/>

            <FieldArray name='eligibility_groups'>
                {({fields = []}) => {
                    return (
                        <React.Fragment>
                            {fields.map((field, index) => {
                                const {segment, group_id: groupId} = fields.value[index];
                                const {label: segmentLabel} = ELIGIBILITY_OPTIONS.find(getEqual(segment, 'value'));

                                const onGenerateGroupId = async () => {
                                    const groupId = await getGeneratedGroupId();

                                    fields.update(index, {segment, group_id: groupId});
                                };

                                return (
                                    <Row className='eligibility-section__row'>
                                        <Column sm={4}>
                                            <Field name={`${field}.segment`}>{props => <Input {...props} type='hidden'/>}</Field>
                                            <Input value={segmentLabel} disabled label='Eligibility'/>
                                        </Column>
                                        <Column sm={4}>
                                            <Field name={`${field}.group_id`}>
                                                {props => (
                                                    <Input {...props}
                                                        maxLength={64}
                                                        icon={ICON_TYPES.copy}
                                                        onIconClick={() => copyToClipboard(props.value)}
                                                        tooltipProps={{content: tooltip, hideOnClick: false, onHidden: () => setTooltip('Copy')}}
                                                        placeholder=''
                                                        label='Group ID'
                                                        disabled={isEditDisabled}/>
                                                )}
                                            </Field>
                                        </Column>

                                        {!isEditDisabled && !groupId && (
                                            <Column sm={4} className='eligibility-section__actions'>
                                                <Button data-testid='generate-group-id'
                                                    type={BUTTON_TYPES.tertiary}
                                                    className='eligibility-section__actions__generate-group'
                                                    onClick={onGenerateGroupId}>
                                                    GENERATE GROUP ID
                                                </Button>
                                            </Column>
                                        )}
                                    </Row>
                                );
                            })}
                        </React.Fragment>
                    );
                }}
            </FieldArray>
        </ContentSection>
    );
};

EligibilityInformation.propTypes = {
    isEditDisabled: PropTypes.bool.isRequired
};

export {validate, EligibilityInformation as TestableEligibilityInformation};
export default React.memo(EligibilityInformation);

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {equal} from '../../../../utils';

const ImageSelector = ({onSelect, images, initialImage}) => {
    const [selectedImage, setSelectedImage] = useState(initialImage || null);

    useEffect(() => {
        if (!selectedImage && images.length > 0) {
            setSelectedImage(images[0]);
            onSelect(images[0]);
        } else {
            setSelectedImage(images[images.length - 1]);
            onSelect(images[images.length - 1]);
        }
    }, [images]);

    const onSelectImage = imageSrc => {
        setSelectedImage(imageSrc);
        onSelect(imageSrc);
    };

    const isImageActive = imageSrc => equal(selectedImage, imageSrc);

    const selectImage = imageSrc => (
        <div key={imageSrc}
            className={classnames('image-wrapper', 'mr-6', {'image-wrapper_active': isImageActive(imageSrc)})}
            onClick={() => onSelectImage(imageSrc)}>
            <img src={imageSrc} alt=''/>
        </div>
    );

    return (
        <div className='image-selector'>
            {images.map(selectImage)}
        </div>
    );
};

ImageSelector.propTypes = {
    onSelect: PropTypes.func,
    images: PropTypes.arrayOf(PropTypes.string),
    initialImage: PropTypes.string
};

export default ImageSelector;

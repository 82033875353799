import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import CustomizablePopup from '../../shared/CustomizablePopup';
import DynamicActionBar from '../../shared/DynamicActionBar';
import InformationRow from '../../shared/InformationRow';
import withPopup from '../../../HOC/withPopup';
import {redirectTo} from '../../../actions/general';
import {clearBroadcast, requestBroadcast, requestBroadcastUpdating, requestBroadcastDeleting, requestWalletLinks} from '../../../actions/broadcaster';
import {getProfilePermissions} from '../../../selectors/general';
import {getBroadcast, getWalletLinks} from '../../../selectors/broadcaster';
import {convertDateToTimeZone, equal, formatDate, getEqual, getTimeZoneLabelByOffset, removeOffset} from '../../../utils';
import {ROUTES, BROADCAST_STATUSES} from '../../../constants';
import {BROADCAST_USA_TIMEZONES_OPTIONS} from '../../../options';
import analyticsService from '../../../analyticsService';
import './index.scss';

const POPUP_ID = 'broadcastOverviewPopup';

const ITEM_TYPES = {
    walletCard: 'wallet_link',
    document: 'file',
    additionalText: 'text'
};

const previewItems = {
    [ITEM_TYPES.walletCard]: walletLink => (
        <Button type={BUTTON_TYPES.secondary} href={walletLink} target='_blank' className='custom-preview__button mb-10'>
            <Icon type={ICON_TYPES.wallet}/>
            View Benefit Card
        </Button>
    ),
    [ITEM_TYPES.document]: documentLink => (
        <Button type={BUTTON_TYPES.secondary} href={documentLink} target='_blank' className='custom-preview__button mb-10'>
            <Icon type={ICON_TYPES.pdfFile}/>
            View Attachment
        </Button>
    ),
    [ITEM_TYPES.additionalText]: additionalText => additionalText && <div className='custom-preview__description mb-10' dangerouslySetInnerHTML={{__html: additionalText}}/>
};

const isWithinTimeFrame = (scheduledTime, frameInMilliseconds) => {
    const currentDate = new Date();
    const currentDateInChicago = convertDateToTimeZone(currentDate);
    const scheduledDate = new Date(scheduledTime);
    const timeDifference = scheduledDate - currentDateInChicago;

    return timeDifference < frameInMilliseconds;
};

const BroadcastOverview = ({openPopup, closePopup}) => {
    const [isEditableByTime, setIsEditableByTime] = useState(false);
    const [isCancelableByTime, setIsCancelableByTime] = useState(false);
    const dispatch = useDispatch();
    const {id} = useParams();
    const profilePermissions = useSelector(getProfilePermissions);
    const broadcast = useSelector(getBroadcast);

    useEffect(() => {
        dispatch(requestBroadcast(id));

        return () => dispatch(clearBroadcast());
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(requestWalletLinks());
    }, [dispatch]);

    useEffect(() => {
        const isDraft = equal(BROADCAST_STATUSES.draft, broadcast.status);
        if (isDraft) {
            return;
        }
        const checkIsEditable = () => {
            setIsEditableByTime(isWithinTimeFrame(broadcast.scheduled_for, 60 * 60 * 1000));
            setIsCancelableByTime(isWithinTimeFrame(broadcast.scheduled_for, 60 * 1000));
        };

        checkIsEditable();

        const interval = setInterval(checkIsEditable, 60 * 1000);

        return () => clearInterval(interval);
    }, [broadcast.scheduled_for]);

    const onOpenPopup = popupProps => openPopup({type: POPUP_TYPES.simple, children: <CustomizablePopup {...popupProps}/>});

    const onEdit = ({target}) => {
        analyticsService.trackClicked(target.textContent, {broadcast_id: broadcast.id});
        dispatch(redirectTo(`${ROUTES.broadcastEditor}/${broadcast.id}`));
    };

    const onOpenCancellationPopup = ({target}) => {
        const onCancel = async () => {
            const {isSuccess} = await dispatch(requestBroadcastUpdating({status: BROADCAST_STATUSES.canceled}, broadcast.id, broadcast));

            return isSuccess ? dispatch(redirectTo(ROUTES.broadcasterWelcome)) : false;
        };
        const actionBar = (
            <React.Fragment>
                <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>No</Button>
                <Button type={BUTTON_TYPES.destructive} onClick={onCancel}>Yes</Button>
            </React.Fragment>
        );

        analyticsService.trackClicked(target.textContent, {broadcast_id: broadcast.id});

        return onOpenPopup({title: 'Cancel Broadcast', content: 'Are you sure you want to cancel?', actionBar});
    };

    const onOpenDeletePopup = ({target}) => {
        const onDelete = async () => {
            const {isSuccess} = await dispatch(requestBroadcastDeleting({status: BROADCAST_STATUSES.draft}, broadcast.id, broadcast));

            return isSuccess ? dispatch(redirectTo(ROUTES.broadcasterWelcome)) : false;
        };
        const actionBar = (
            <React.Fragment>
                <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>No</Button>
                <Button type={BUTTON_TYPES.destructive} onClick={onDelete}>Yes</Button>
            </React.Fragment>
        );

        analyticsService.trackClicked(target.textContent, {broadcast_id: broadcast.id});

        return onOpenPopup({title: 'Delete Broadcast', content: 'Are you sure you want to delete?', actionBar});
    };

    const {status, scheduled_for: deliveryTime, content = {}} = broadcast;
    const [isDraft, isPending, isScheduled, isSent] = [
        BROADCAST_STATUSES.draft,
        BROADCAST_STATUSES.pending,
        BROADCAST_STATUSES.scheduled,
        BROADCAST_STATUSES.sent
    ].map(getEqual(status));
    const isActionBar = (profilePermissions.broadcast_edit || profilePermissions.broadcast_delete) && (isScheduled || isPending || isDraft);

    const getPreviewItem = item => {
        const walletLink = equal(item.type, ITEM_TYPES.walletCard) && item.link;
        const documentLink = equal(item.type, ITEM_TYPES.document) && item.description;
        const additionalText = equal(item.type, ITEM_TYPES.additionalText) && item.body;
        const previewComponent = previewItems[item.type];

        return previewComponent ? previewComponent(walletLink || documentLink || additionalText) : null;
    };

    const timeZoneLabel = getTimeZoneLabelByOffset(deliveryTime, BROADCAST_USA_TIMEZONES_OPTIONS);

    const additionalFields = content?.blocks;
    const additionalItems = additionalFields && additionalFields.map(item => getPreviewItem(item));

    const walletLinks = useSelector(getWalletLinks);
    const walletLinkItem = additionalFields?.find(item => equal(item.type, ITEM_TYPES.walletCard));
    const walletLinkObject = walletLinks?.find(link => equal(link.link, walletLinkItem?.link));
    const walletLinkName = walletLinkObject ? walletLinkObject.name : '';
    const deliveryTimeWithoutOffset = removeOffset(deliveryTime);
    const scheduledText = `This broadcast has not been sent and is scheduled for ${formatDate(deliveryTimeWithoutOffset, 'MM/dd/yyyy')} at ${formatDate(deliveryTimeWithoutOffset, 'h:mm aa')} ${timeZoneLabel}`;
    const sentText = `This broadcast was sent on ${formatDate(deliveryTimeWithoutOffset, 'MM/dd/yyyy')} at ${formatDate(deliveryTimeWithoutOffset, 'h:mm aa')} ${timeZoneLabel}`;
    const draftText = 'This broadcast is an incomplete draft and has not been scheduled.';

    return (
        <Row className='broadcast-overview'>
            <Column sm={12} className='mb-13'>
                {isScheduled && <Alert className='mb-12' type={ALERT_TYPES.warning} description={scheduledText}/>}
                {isSent && <Alert className='mb-12' type={ALERT_TYPES.success} description={sentText}/>}
                {isDraft && <Alert className='mb-12' type={ALERT_TYPES.info} description={draftText}/>}
            </Column>
            <Column sm={12} className='mb-13'>
                <ContentSection>
                    <InformationRow title='Notification Title'>{content.title}</InformationRow>

                    <InformationRow title='Recipients'>Only Eligible Employees{walletLinkName && ` with ${walletLinkName}`}, not inclusive of spouses or dependents</InformationRow>
                </ContentSection>
            </Column>

            <Column sm={12}>
                <ContentSection>
                    <Row>
                        <Column sm={2}>Content</Column>
                        <Column sm={7}>
                            <div className='custom-preview'>
                                <div className='screen'>
                                    {content.title && (
                                        <div className='custom-preview__title'>{content.title}</div>
                                    )}

                                    {content.image_url && (
                                        <div className='custom-preview__logo'>
                                            <img src={content.image_url} alt=''/>
                                        </div>
                                    )}

                                    {content.details && (
                                        <div className='custom-preview__details mb-10'>
                                            <div data-testid='custom-page-body' dangerouslySetInnerHTML={{__html: content.details}}/>
                                        </div>
                                    )}

                                    {additionalItems && (
                                        <div>{additionalItems}</div>
                                    )}
                                </div>
                            </div>
                        </Column>
                    </Row>
                    <Row>
                        <Column sm={1}/>
                        <Column sm={10}>
                            {isActionBar && (
                                <DynamicActionBar>
                                    {profilePermissions.broadcast_delete && (
                                        <React.Fragment>
                                            {!isDraft && <Button type={BUTTON_TYPES.tertiary} disabled={isCancelableByTime} onClick={onOpenCancellationPopup}>Cancel</Button>}
                                            {isDraft && <Button type={BUTTON_TYPES.tertiary} onClick={onOpenDeletePopup}>Delete</Button>}
                                        </React.Fragment>
                                    )}
                                    {profilePermissions.broadcast_edit && <Button type={BUTTON_TYPES.primary} disabled={isEditableByTime} onClick={onEdit}>Edit</Button>}
                                </DynamicActionBar>
                            )}
                        </Column>
                    </Row>
                </ContentSection>
            </Column>
        </Row>
    );
};

BroadcastOverview.propTypes = {
    openPopup: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired
};

export {BroadcastOverview as TestableBroadcastOverview};
export default withPopup(POPUP_ID)(BroadcastOverview);

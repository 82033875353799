import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Alert from '@frontend/ui-kit/Components/Alert';
import PageHeader from '../../../Components/shared/PageHeader';
import BroadcastWizard from '../../../Components/Broadcaster/BroadcastWizard';
import {getWizardActiveStep} from '../../../selectors/shared';

const BROACAST_ALERT_TEXT = 'Please note: Only members who have updated their app since July 15, 2024 will receive Broadcasts.';

const getHeaderInfo = (activeStep, broadcastId) => {
    return [
        {
            title: `${broadcastId ? 'Edit' : 'Create'} Broadcast`,
            caption: `
                Your broadcast contains a push notification and a message displayed in a members 
                app inbox. Add links, wallet cards, PDFs, and online resources to inform members.
            `
        },
        {
            title: 'Create Broadcast',
            caption: 'Select a date and time for your broadcast'
        }
    ][activeStep];
};

const BroadcastEditor = () => {
    const wizardActiveStep = useSelector(getWizardActiveStep);
    const {id: broadcastId} = useParams();

    const {title, caption} = getHeaderInfo(wizardActiveStep, broadcastId);

    return (
        <React.Fragment>
            <Row>
                <Column sm={8}>
                    <PageHeader title={title} caption={caption}/>
                </Column>

                <Column sm={12}>
                    <Alert className='mb-12' description={BROACAST_ALERT_TEXT}/>
                </Column>
            </Row>

            <BroadcastWizard/>
        </React.Fragment>
    );
};

export {BroadcastEditor as TestableBroadcastEditor};
export default React.memo(BroadcastEditor);

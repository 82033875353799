import {
    SET_ACTIVE_BROADCASTS_TAB,
    CLEAR_BROADCAST,
    RECEIVE_BROADCAST,
    RECEIVE_BROADCASTS,
    RECEIVE_BROADCAST_TEMPLATES,
    RECEIVE_WALLET_LINKS,
    SET_BROADCAST_TEMPLATE
} from './actionTypes';
import {getDraftFromHtml} from '../Components/shared/WysiwygEditor';
import {getActiveCompany} from '../selectors/general';
import apiServices from '../apiServices';
import {FAMILY_SEGMENTS, LOCAL_STORAGE_ITEMS} from '../constants';

const getEnhancedBroadcast = broadcast => {
    // TODO: remove default for family_segment when autogenerated segment fields are ready (19.08.2020, Oleh);
    const {'relation-in': relationIn} = broadcast?.condition_templates_arguments || {};
    // TODO: remove this hack. This logic is strongly tied to array structure we get from the backend (30.09.2020, Oleh);
    return {...broadcast, family_segment: relationIn ? relationIn.relations.join('_') : FAMILY_SEGMENTS.all};
};

const receiveBroadcastTemplates = broadcastTemplates => ({type: RECEIVE_BROADCAST_TEMPLATES, broadcastTemplates});
const receiveBroadcasts = broadcasts => ({type: RECEIVE_BROADCASTS, broadcasts});
const receiveBroadcast = broadcast => ({type: RECEIVE_BROADCAST, broadcast});
const receiveWalletLinks = walletLinks => ({type: RECEIVE_WALLET_LINKS, walletLinks});

export const setActiveBroadcastsTab = activeTab => ({type: SET_ACTIVE_BROADCASTS_TAB, activeTab});

export const requestWalletLinks = () => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);
    const {data, isSuccess} = await apiServices.getWalletLinks({companyAlias});
    const walletLinks = isSuccess ? data : [];

    dispatch(receiveWalletLinks(walletLinks));
};

const ENHANCED_ADDITIONAL_BLOCKS = {
    wallet_link: (block, index) => ({
        type: 'walletCard',
        link: block.link,
        name: 'link',
        key: index
    }),
    file: (block, index) => ({
        type: 'document',
        description: block.media_url,
        name: block.description,
        key: index
    }),
    additionalText: (block, index) => ({
        type: 'additionalText',
        body: getDraftFromHtml(block.body),
        name: 'body',
        key: index
    })
};

const getEnhancedBlock = (block, index) => {
    const transform = ENHANCED_ADDITIONAL_BLOCKS[block.type];

    return transform(block, index);
};

const getContent = (template, enhancedBlocks) => ({
    title: template.content.title,
    details: getDraftFromHtml(template.content.details),
    blocks: template.content?.blocks ? enhancedBlocks(template.content.blocks) : []
});

export const requestBroadcastTemplates = params => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);
    const {data, isSuccess} = await apiServices.getBroadcastTemplates({companyAlias, ...params});
    const enhancedBlocks = blocks => blocks?.map(getEnhancedBlock);

    const broadcastTemplates = !isSuccess ? [] : data.data.map(template => {
        const templateContent = getContent(template, enhancedBlocks);

        return {
            templateId: template.id,
            title: template.name,
            description: template.description,
            broadcast: {
                name: template.content.push_title,
                content: templateContent
            }
        };
    });

    dispatch(receiveBroadcastTemplates(broadcastTemplates));

    return {broadcastTemplates, isSuccess};
};

export const requestBroadcasts = params => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);
    const {data, isSuccess} = await apiServices.getBroadcasts({companyAlias, ...params});
    const {data: broadcasts = [], count = 0} = isSuccess ? data : {};

    dispatch(receiveBroadcasts(broadcasts));

    return {broadcasts, count, isSuccess};
};

export const setBroadcastTemplate = broadcastTemplate => {
    localStorage.setItem(LOCAL_STORAGE_ITEMS.broadcastTemplate, JSON.stringify(broadcastTemplate));

    return {type: SET_BROADCAST_TEMPLATE, broadcastTemplate};
};

export const clearBroadcast = () => ({type: CLEAR_BROADCAST});

export const requestBroadcast = id => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);
    const {data, isSuccess} = await apiServices.getBroadcast({companyAlias, id});
    const broadcast = isSuccess ? data.data : {};
    const enhancedBroadcast = getEnhancedBroadcast(broadcast);

    dispatch(receiveBroadcast(enhancedBroadcast));

    return {broadcast: enhancedBroadcast};
};

const getRequestBroadcastSubmission = requestFunc => (broadcast, id = null) => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);
    const {data, isSuccess} = await requestFunc({companyAlias, id, broadcast});
    const {data: updatedBroadcast, messages} = data;

    const enhancedBroadcast = getEnhancedBroadcast(updatedBroadcast);
    dispatch(receiveBroadcast(enhancedBroadcast));

    return {broadcast: enhancedBroadcast, isSuccess, submissionErrors: messages};
};
export const requestBroadcastCreating = getRequestBroadcastSubmission(apiServices.createBroadcast);
export const requestBroadcastUpdating = getRequestBroadcastSubmission(apiServices.updateBroadcast);
export const requestBroadcastDeleting = getRequestBroadcastSubmission(apiServices.deleteBroadcast);

export const requestBroadcastTesting = broadcast => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.createCommunicationByEmail({companyAlias, broadcast});

    return {isSuccess, submissionErrors: data.messages};
};

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Sorter from '@frontend/ui-kit/Components/Sorter';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import Select from '@frontend/ui-kit/Components/Select';
import ConfirmationPopup from './ConfirmationPopup';
import {Field, FieldArray} from '../../../shared/FormComponents';
import FileUploader from '../../../shared/FileUploader';
import WysiwygEditor, {getHtmlFromDraft} from '../../../shared/WysiwygEditor';
import withPopup from '../../../../HOC/withPopup';
import useFormState from '../../../../hooks/useFormState';
import {requestWalletLinks} from '../../../../actions/broadcaster';
import {getWalletLinks} from '../../../../selectors/broadcaster';
import {equal, generateUniqueId, compose} from '../../../../utils';
import {WYSIWYG_TOOLBAR_OPTIONS, ACCEPTABLE_TEXT_FORMATS} from '../../../../constants';

const POPUP_ID = 'customFieldDelitionPopup';
const DRUG_START_DELAY = 250;
const DRUG_START_TOLERANCE = 5;

const AdditionalContentFields = ({openPopup, closePopup}) => {
    const dispatch = useDispatch();
    const {values} = useFormState();
    const walletLinks = useSelector(getWalletLinks);

    const moveArrayItem = (fields, oldId, newId) => {
        const {value: arr} = fields;
        const updatedArray = [...arr];
        const oldIndex = arr.findIndex(item => equal(`$${item.key}`, oldId));
        const newIndex = arr.findIndex(item => equal(`$${item.key}`, newId));

        const [item] = updatedArray.splice(oldIndex, 1);
        updatedArray.splice(newIndex, 0, item);
        fields.swap(oldIndex, newIndex);
    };

    const [isWalletCardAdded, setIsWalletCardAdded] = useState(false);
    const [isDocumentAdded, setIsDocumentAdded] = useState(false);

    useEffect(() => {
        dispatch(requestWalletLinks());
    }, [dispatch]);

    useEffect(() => {
        const additionalFields = values?.content?.blocks || [];
        setIsWalletCardAdded(additionalFields.some(item => equal(item.type, 'walletCard')));
        setIsDocumentAdded(additionalFields.some(item => equal(item.type, 'document')));
    }, [values]);

    const onAddDocument = fields => {
        const uniqueKey = generateUniqueId();
        const fieldType = 'document';
        const fieldName = 'description';

        fields.push({
            name: fieldName,
            key: uniqueKey,
            type: fieldType
        });

        setIsDocumentAdded(true);
    };

    const onAddAdditionalText = fields => {
        const uniqueKey = generateUniqueId();
        const fieldName = 'body';
        const fieldType = 'additionalText';

        fields.push({
            name: fieldName,
            key: uniqueKey,
            type: fieldType
        });
    };

    const onAddWalletCard = fields => {
        if (!isWalletCardAdded) {
            const uniqueKey = generateUniqueId();
            const fieldName = 'link';
            const fieldType = 'walletCard';

            fields.push({
                name: fieldName,
                key: uniqueKey,
                type: fieldType
            });

            setIsWalletCardAdded(true);
        }
    };

    const onOpenConfirmationPopup = (fields, uniqueKey, fieldType) => {
        const onDeleteItem = () => {
            const {value: additionalFields} = fields;
            const itemIndex = additionalFields.findIndex(item => equal(item.key, uniqueKey));
            fields.remove(itemIndex);

            if (equal(fieldType, 'walletCard')) {
                setIsWalletCardAdded(false);
            }

            if (equal(fieldType, 'document')) {
                setIsDocumentAdded(false);
            }

            closePopup();
        };

        return openPopup({type: POPUP_TYPES.simple, children: <ConfirmationPopup onClose={closePopup} onDelete={onDeleteItem}/>});
    };

    const walletLinkOptions = [
        {label: 'Choose nothing', value: null},
        ...walletLinks.map(({name, link}) => ({label: name, value: link}))
    ];

    const fieldComponents = {
        walletCard: props => <Select {...props} options={walletLinkOptions} placeholder='Click to select...' label='Link Wallet Card' data-testid='additional-field-wallet'/>,
        document: props => <FileUploader {...props} accept={ACCEPTABLE_TEXT_FORMATS} maxSize={10} data-testid='additional-field-file'/>,
        additionalText: props => (
            <WysiwygEditor {...props}
                maxLength={500}
                toolbar={WYSIWYG_TOOLBAR_OPTIONS}
                className='mb-12'
                wrapperClassName='editor-wrapper'
                toolbarClassName='wysiwyg-toolbar'
                editorClassName='wysiwyg-editor'
                placeholder='Body'/>
        )
    };

    const getSortableField = (field, props) => {
        const component = fieldComponents[field.type];
        return component ? component(props) : null;
    };

    const dragActivationConstraintProps = {delay: DRUG_START_DELAY, tolerance: DRUG_START_TOLERANCE};

    const normalizeWysiwygEditorValue = value => {
        const normalizedValue = {__html: getHtmlFromDraft(value)};

        return normalizedValue;
    };

    return (
        <FieldArray name='content.blocks'>
            {({fields = []}) => {
                const {value: additionalFieldsArray} = fields;

                return (
                    <React.Fragment>
                        <Sorter className='sortable-additional-fields' dragActivationConstraint={dragActivationConstraintProps} onChange={({oldIndex, newIndex}) => moveArrayItem(fields, oldIndex, newIndex)}>
                            {additionalFieldsArray && additionalFieldsArray.map((item, index) => {
                                const {type: fieldType, key: fieldKey, name: fieldName} = item;
                                const isHintShowed = equal(fieldType, 'walletCard') && item.link;
                                const isAdditioanlTextField = equal(fieldType, 'additionalText');

                                return (
                                    <React.Fragment key={fieldKey}>
                                        <div className='sort-item' data-testid={`additional-field-${fieldType}`}>
                                            <Icon type={ICON_TYPES.drag} className='mr-8'/>
                                            {!isAdditioanlTextField && (

                                                <Field name={`content.blocks[${index}].${fieldName}`}>
                                                    {props => getSortableField(item, props)}
                                                </Field>
                                            )}

                                            {isAdditioanlTextField && (
                                                <Field name={`content.blocks[${index}].${fieldName}`} parse={normalizeWysiwygEditorValue}>
                                                    {props => getSortableField(item, props)}
                                                </Field>
                                            )}
                                            <Button type={BUTTON_TYPES.tertiary}
                                                onClick={() => onOpenConfirmationPopup(fields, fieldKey, fieldType)}
                                                data-testid={`delete-additional-field-${fieldType}`}>
                                                <Icon type={ICON_TYPES.delete}/>
                                            </Button>
                                        </div>
                                        {isHintShowed && (
                                            <div className='wallet-copy'>Messages with an attached wallet item will only be sent to Eligible Employees with the selected benefit.</div>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </Sorter>

                        <div className='button-wrapper'>
                            <Button type={BUTTON_TYPES.secondary}
                                className='mr-12 mb-10'
                                data-testid='button-add-wallet'
                                disabled={isWalletCardAdded}
                                onClick={() => onAddWalletCard(fields)}>
                                <Icon type={ICON_TYPES.inbox}/>
                                Add a Wallet Card
                            </Button>
                            <Button type={BUTTON_TYPES.secondary}
                                className='mr-12 mb-10'
                                data-testid='button-add-document'
                                disabled={isDocumentAdded}
                                onClick={() => onAddDocument(fields)}>
                                <Icon type={ICON_TYPES.fileFull}/>
                                Add Document
                            </Button>
                            <Button type={BUTTON_TYPES.secondary}
                                className='mr-12 mb-10'
                                data-testid='button-add-text'
                                onClick={() => onAddAdditionalText(fields)}>
                                <Icon type={ICON_TYPES.bold}/>
                                Add Body Text
                            </Button>
                        </div>
                    </React.Fragment>
                );
            }}
        </FieldArray>
    );
};

AdditionalContentFields.propTypes = {
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export default compose(
    withPopup(POPUP_ID),
    React.memo
)(AdditionalContentFields);

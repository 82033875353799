import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Wizard from '../../../Containers/shared/Wizard';
import ContentStep from '../BroadcastWizardSteps/ContentStep';
import InformationStep from '../BroadcastWizardSteps/InformationStep';
import {clearBroadcast, setBroadcastTemplate} from '../../../actions/broadcaster';

const WIZARD_STEPS = [
    {StepComponent: ContentStep, stepName: 'content', stepTitle: 'Broadcast Content'},
    {StepComponent: InformationStep, stepName: 'information', stepTitle: 'Schedule'}
];

const BroadcastWizard = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearBroadcast());
            dispatch(setBroadcastTemplate(null));
        };
    }, [dispatch]);

    return <Wizard steps={WIZARD_STEPS}/>;
};

export {BroadcastWizard as TestableBroadcastWizard};
export default BroadcastWizard;

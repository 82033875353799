import React from 'react';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import BroadcastOverview from '../../../Components/Broadcaster/BroadcastOverview';

const BroadcastDetails = () => {
    return (
        <React.Fragment>
            <Row>
                <Column sm={9}>
                    <PageHeader title='Broadcast Details'/>
                </Column>
            </Row>

            <BroadcastOverview/>
        </React.Fragment>
    );
};

export {BroadcastDetails as TestableBroadcastDetails};
export default React.memo(BroadcastDetails);
